import React, { forwardRef, HTMLAttributes } from 'react';

type WheelProps = {
  slotsColors: string[];
} & HTMLAttributes<SVGSVGElement>;

export default forwardRef<SVGSVGElement, WheelProps>(({ slotsColors, ...restProps }, ref) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      viewBox="0 0 1000 1000"
      className="eg-wheel-svg"
      {...restProps}
    >
      <path
        id="slot00"
        d="M500.514 390.727C480.569 390.727 461.878 396.087 445.794 405.438L280.919 119.866C345.503 82.4527 420.505 61.0307 500.514 61.0311C580.522 61.0314 655.525 82.4531 720.109 119.866L555.234 405.438C539.15 396.087 520.459 390.727 500.514 390.727Z"
        fill={slotsColors[0]}
      />
      <path
        id="slot01"
        d="M595.15 445.577C585.59 428.932 571.826 415.091 555.234 405.438L720.109 119.866C786.711 158.449 842.23 214.041 880.721 280.703C917.941 345.166 939.242 419.974 939.242 499.759C939.242 499.822 939.241 499.885 939.241 499.948C939.24 500.037 939.239 500.126 939.239 500.215L609.54 500.215C609.54 500.142 609.541 500.068 609.543 499.995C609.544 499.916 609.546 499.837 609.546 499.759C609.545 480.037 604.307 461.539 595.15 445.577Z"
        fill={slotsColors[1]}
      />
      <path
        id="slot02"
        d="M594.751 554.623C604.081 538.631 609.459 520.048 609.541 500.215L939.24 500.215C939.158 580.112 917.717 655.004 880.324 719.498C841.772 785.988 786.265 841.423 719.715 879.882L554.84 594.313C571.373 584.784 585.13 571.103 594.751 554.623Z"
        fill={slotsColors[2]}
      />
      <path
        id="slot03"
        d="M446.186 594.314C462.181 603.523 480.732 608.793 500.514 608.793C520.296 608.793 538.847 603.523 554.84 594.313L719.715 879.883C655.219 917.155 580.359 938.488 500.514 938.488L500.514 938.487C420.668 938.487 345.808 917.155 281.313 879.882L446.186 594.314Z"
        fill={slotsColors[3]}
      />
      <path
        id="slot04"
        d="M61.7887 500.215L391.488 500.215C391.569 520.048 396.945 538.63 406.278 554.623L406.277 554.623C415.897 571.103 429.655 584.784 446.187 594.313L281.313 879.882C214.763 841.422 159.256 785.988 120.705 719.499C83.3104 655.004 61.8701 580.112 61.7887 500.215Z"
        fill={slotsColors[4]}
      />
      <path
        id="slot05"
        d="M280.919 119.866L445.794 405.438C429.203 415.09 415.438 428.932 405.878 445.577C396.719 461.538 391.482 480.037 391.482 499.759C391.482 499.912 391.486 500.063 391.488 500.216L61.789 500.216L61.7859 499.761C61.7856 419.974 83.0864 345.165 120.307 280.703C158.797 214.041 214.316 158.448 280.919 119.866Z"
        fill={slotsColors[5]}
      />
    </svg>
  );
});
