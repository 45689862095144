import React, { forwardRef, HTMLAttributes } from 'react';

type WheelProps = {
  slotsColors: string[];
} & HTMLAttributes<SVGSVGElement>;

export default forwardRef<SVGSVGElement, WheelProps>(({ slotsColors, ...restProps }, ref) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      viewBox="0 0 1000 1000"
      className="eg-wheel-svg"
      {...restProps}
    >
      <path
        d="M331.92 94.218l126.309 304.939a109.629 109.629 0 0127.294-7.36c18.459-2.488 37.799-.284 56.248 7.358L668.08 94.218c-112.053-46.414-232.312-43.015-336.16 0"
        id="slot04"
        fill={slotsColors[4]}
      />
      <path
        d="M600.84 458.23l304.941-126.311C862.766 228.071 780.133 140.632 668.08 94.218L541.77 399.155c18.447 7.641 33.68 19.759 44.974 34.57a109.653 109.653 0 0114.097 24.505"
        id="slot05"
        fill={slotsColors[5]}
      />
      <path
        d="M600.842 541.769L905.781 668.08c46.414-112.054 43.016-232.312 0-336.161L600.84 458.23a109.488 109.488 0 017.446 27.912c2.356 18.272.113 37.387-7.444 55.627z"
        id="slot06"
        fill={slotsColors[6]}
      />
      <path
        d="M541.769 600.838L668.08 905.781c103.848-43.015 191.287-125.648 237.701-237.702l-304.939-126.31c-.059.141-.123.278-.181.419-7.674 18.312-19.766 33.439-34.523 44.656a109.636 109.636 0 01-24.37 13.994"
        id="slot07"
        fill={slotsColors[7]}
      />
      <path
        d="M458.229 600.844L331.92 905.781c112.053 46.414 232.312 43.016 336.16 0L541.769 600.838a109.52 109.52 0 01-27.749 7.426c-18.321 2.391-37.498.157-55.791-7.42"
        id="slot00"
        fill={slotsColors[0]}
      />
      <path
        d="M331.92 905.781l126.306-304.938c-18.295-7.578-33.435-19.558-44.7-34.204a109.479 109.479 0 01-14.369-24.869L94.218 668.08c43.016 103.848 125.648 191.287 237.702 237.701z"
        id="slot01"
        fill={slotsColors[1]}
      />
      <path
        d="M399.153 458.227L94.218 331.919c-46.414 112.054-43.015 232.312 0 336.161l304.939-126.31a109.65 109.65 0 01-7.338-27.13c-2.504-18.368-.359-37.614 7.165-55.988.057-.142.11-.284.169-.425"
        id="slot02"
        fill={slotsColors[2]}
      />
      <path
        d="M458.229 399.157L331.92 94.218C228.071 137.234 140.632 219.866 94.219 331.919l304.934 126.308c7.556-18.241 19.487-33.343 34.072-44.598a109.567 109.567 0 0125.004-14.472"
        id="slot03"
        fill={slotsColors[3]}
      />
    </svg>
  );
});
