import React, { FC } from 'react';

type WheelPointerProps = {
  color: string;
  className?: string;
};

export const WheelPointer: FC<WheelPointerProps> = ({ color, className }) => {
  return (
    <svg
      viewBox="0 0 283 319"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`eg-wheel-pointer ${className || ''}`}
    >
      <path
        d="M123.149 12.3498C130.11 -3.71418 152.89 -3.71413 159.851 12.3499L280.509 290.798C286.232 304.004 276.551 318.75 262.158 318.75H20.8419C6.44915 318.75 -3.23175 304.004 2.49081 290.798L123.149 12.3498Z"
        fill={color}
      />
    </svg>
  );
};
