import React, { forwardRef, HTMLAttributes } from 'react';

type WheelProps = {
  slotsColors: string[];
} & HTMLAttributes<SVGSVGElement>;

export default forwardRef<SVGSVGElement, WheelProps>(({ slotsColors, ...restProps }, ref) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      viewBox="0 0 1000 1000"
      className="eg-wheel-svg"
      {...restProps}
    >
      <path
        d="M145.966 755.735l265.791-192.18a109.033 109.033 0 01-18.375-40.796 107.432 107.432 0 01-2.505-22.913l-328.941-.464c.112 92.932 30.294 182.618 84.03 256.353z"
        id="slot02"
        fill={slotsColors[2]}
      />
      <path
        d="M466.195 602.98L365.017 914.376c91.472 29.722 185.593 27.849 270.462.412l-102.07-311.709a108.825 108.825 0 01-11.214 2.977c-18.005 3.767-37.229 3.023-56-3.076z"
        id="slot00"
        fill={slotsColors[0]}
      />
      <path
        d="M587.745 563.811l266.396 192.975c26.266-36.06 47.365-76.797 61.889-121.498 14.525-44.701 21.399-90.059 21.346-134.67l-328.94-.464c.013 11.086-1.695 22.358-5.306 33.466-.047.144-.099.285-.146.429-3.605 10.94-8.797 20.917-15.24 29.762"
        id="slot08"
        fill={slotsColors[8]}
      />
      <path
        d="M587.549 436.449l265.796-192.184c-52.533-72.083-127.578-128.92-219.05-158.641L533.114 397.019c18.929 6.15 35.022 16.976 47.417 30.768a109.248 109.248 0 017.018 8.661"
        id="slot06"
        fill={slotsColors[6]}
      />
      <path
        d="M465.902 396.921L363.833 85.212c-86.815 28.067-163.948 82.885-218.663 158.002l266.388 192.969a107.447 107.447 0 0114.908-16.541 108.987 108.987 0 0139.436-22.721"
        id="slot04"
        fill={slotsColors[4]}
      />
      <path
        d="M853.345 244.265L587.549 436.449a108.968 108.968 0 0118.548 41.558 107.507 107.507 0 012.339 22.147l328.94.464c-.113-92.932-30.294-182.618-84.031-256.353"
        id="slot07"
        fill={slotsColors[7]}
      />
      <path
        d="M411.558 436.183L145.17 243.214c-26.265 36.06-47.365 76.796-61.889 121.498-14.524 44.701-21.399 90.059-21.345 134.67l328.941.464c-.014-10.943 1.65-22.066 5.165-33.036.046-.144.087-.289.134-.433 3.61-11.109 8.854-21.233 15.382-30.194"
        id="slot03"
        fill={slotsColors[3]}
      />
      <path
        d="M533.116 397.02L634.295 85.624c-91.472-29.721-185.594-27.85-270.462-.412l102.069 311.709a109.19 109.19 0 0110.766-2.881c18.134-3.874 37.518-3.171 56.448 2.98"
        id="slot05"
        fill={slotsColors[5]}
      />
      <path
        d="M411.757 563.555l-265.791 192.18c52.533 72.083 127.579 128.92 219.05 158.641l101.177-311.397c-18.773-6.1-34.763-16.796-47.116-30.427a108.879 108.879 0 01-7.32-8.997"
        id="slot01"
        fill={slotsColors[1]}
      />
      <path
        d="M587.745 563.811a107.554 107.554 0 01-15.494 17.065 109.048 109.048 0 01-38.842 22.203l102.07 311.709c86.814-28.067 163.947-82.885 218.662-158.002L587.745 563.811z"
        id="slot09"
        fill={slotsColors[9]}
      />
    </svg>
  );
});
