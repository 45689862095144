import React, { FC, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { Loader } from '@alfalab/core-components-loader';

import { SfCrossMIcon } from '@alfalab/icons-glyph';
import { Icon36GiftOutline } from '@vkontakte/icons';
import 'slick-carousel/slick/slick.css';

import { GameId, IntegrationsEnum } from 'src/store/game/types';
import { ResultScreen } from '../result-screen/result-screen';
import { Analytics } from '../analytics/analytics';
import { GameWrapProps, GameResult } from '../types';
import { Controls } from './controls';
import { VkResultScreen } from '../vk-result-screen/vk-result-screen';
import { formatSubtitle, getTitles } from '../utils';
import { isUniqLead } from '../../ep/fetchers';

import styles from './index.module.css';
import { Tabs, TabsItem } from '@vkontakte/vkui';
import { Rating } from './rating';

const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const GameWrap: FC<GameWrapProps> = ({
  isDemo = false,
  GameContent,
  settings,
  bridge,
  spaceId,
  startButton,
  showResultScreenManual = false,
  mainSectionClassname,
  style,
  Slider,
  disabled,
  attemptsLeftComponent,
  showJoinButton,
  getResult,
  close,
  onGameOver = () => {},
  lockGame,
  sendAnalytics,
}) => {
  const [gameStarted, setGameStarted] = useState(false);
  const [touched, setTouched] = useState(false);
  const [result, setResult] = useState<GameResult>();
  const [resultScreenVisible, setResultScreenVisible] = useState(false);
  const [gdprChecked, setGdprChecked] = useState(true);
  const [gdprError, setGdprError] = useState(false);
  const [resultFetching, setResultFetching] = useState(false);
  const [resultError, setResultError] = useState('');
  const [error, setError] = useState('');
  const [secondSectionContentType, setSecondSectionContentType] = useState<'content' | 'rating'>(
    'content'
  );

  const [attempts, setAttempts] = useState(0);

  const { resultTitle, resultSubtitle, hasPromocode } = getTitles(settings, result);

  const showCloseIcon = !isDemo && close;

  const {
    mainGameSettings: {
      id,
      collectDataType,
      collectDataPlaceholder,
      integrations,
      yaMetrikaId,
      uniqLeadByUserCredentials,
      campaignType,
      gameId,
    },
    titleText,
    subtitleText,
    titleColor,
    subtitleColor,
    backgroundImgSrc,
    backgroundColor,
    view,
    secondSectionBgColor,
    prizesPreview,
    prizes: { prizes } = { prizes: [] },
    targetInfo,
  } = settings;

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('+7');
  const [validationError, setValidationError] = useState(false);

  const hasYaMetrikaIntegration =
    integrations && integrations.includes(IntegrationsEnum.YA_METRIKA) && yaMetrikaId && !isDemo;

  const sendClientYaMetrikaGoal = useCallback(
    (goal: string) => {
      if (hasYaMetrikaIntegration) {
        const ym = (window as any)['ym'];
        const newYaMetrikaKey = `yaCounter${yaMetrikaId}`;
        const newYm = (window as any)[newYaMetrikaKey];

        if (ym) {
          ym(yaMetrikaId, 'reachGoal', goal);
          return;
        }

        if (newYm) {
          newYm.reachGoal(goal);
          return;
        }

        console.error('Yandex Metrika not found!');
      }
    },
    [hasYaMetrikaIntegration, yaMetrikaId]
  );

  const initYaMetrika = useCallback(() => {
    const ym = (window as any)['ym'];

    ym(yaMetrikaId, 'init', {
      clickmap: false,
      trackLinks: false,
      accurateTrackBounce: false,
      webvisor: false,
    });
  }, [yaMetrikaId]);

  const closeGame = useCallback(() => {
    setResultScreenVisible(false);

    if (close) {
      close();
    }

    if (onGameOver) {
      onGameOver();
    }
  }, [close, onGameOver]);

  const closeDemoGame = useCallback(() => {
    setGameStarted(false);
    setTouched(false);
    setResult(undefined);
    setResultScreenVisible(false);
    setAttempts((value) => value + 1);
    setValidationError(false);
  }, [setValidationError]);

  const handleResultsClose = useCallback(() => {
    if (isDemo) {
      closeDemoGame();
    } else {
      closeGame();
    }
  }, [closeGame, closeDemoGame, isDemo]);

  const validateUserData = useCallback(
    (email: string) => {
      if (collectDataType === 'EMAIL') {
        return EMAIL_REGEXP.test(email.trim());
      } else {
        // TODO: Валидацию остальных номеров сделать через intl-phone-input
        return phone.length > 10;
      }
    },
    [collectDataType, phone]
  );

  const handleStartGame = useCallback(async () => {
    if (disabled) {
      return false;
    }

    if (collectDataType) {
      const valid = validateUserData(email);
      const needGdpr = collectDataType && !gdprChecked;

      if (needGdpr) {
        setGdprError(true);
        return false;
      }

      if (!valid) {
        setValidationError(true);
        return false;
      }

      if (uniqLeadByUserCredentials) {
        const params = { mainSettingId: id } as any;

        if (collectDataType === 'EMAIL') {
          params.email = email;
        } else {
          params.phone = phone;
        }

        const { ok, errorMessage } = await isUniqLead(params);

        if (!ok) {
          setValidationError(true);
          setError(errorMessage || 'Error');
          return false;
        }
      }

      setError('');
      setValidationError(false);
      setGdprError(false);

      sendClientYaMetrikaGoal('eg_lead_send');
    }

    setTouched(true);
    setGameStarted(true);

    return true;
  }, [
    disabled,
    collectDataType,
    validateUserData,
    email,
    gdprChecked,
    uniqLeadByUserCredentials,
    sendClientYaMetrikaGoal,
    phone,
    id,
  ]);

  const handleEndGame = useCallback(
    (gameData?: any) => {
      if (disabled) {
        return Promise.resolve(null);
      }

      setGameStarted(false);
      setResultFetching(true);

      if (!showResultScreenManual) {
        setResultScreenVisible(true);
      }

      const getResultParams = {
        ...gameData,
        email: collectDataType === 'EMAIL' ? email : '',
        phone: collectDataType === 'PHONE' ? phone : '',
        href: window.location.href,
        cookie: document.cookie,
        referrer: document.referrer,
      };

      return getResult(getResultParams)
        .then((result) => {
          setResultFetching(false);
          setResult(result);

          return result;
        })
        .catch((error) => {
          setResultFetching(false);

          const errorMessage =
            error?.response?.data?.codeError === 'UNIQ_LEAD_ERROR'
              ? error?.response?.data?.message
              : 'Произошла ошибка на сервере 😔. Мы уже работаем над ее исправлением.';

          setResultError(errorMessage);

          return null;
        });
    },
    [email, getResult, phone, showResultScreenManual, collectDataType, disabled]
  );

  const showResultScreen = useCallback(() => {
    setResultScreenVisible(true);
  }, []);

  const smallSecondSection = Boolean(bridge) || !collectDataType || Boolean(collectDataType);

  useEffect(() => {
    setTimeout(() => {
      sendClientYaMetrikaGoal('eg_game_open');
    }, 1000);
  }, [sendClientYaMetrikaGoal]);

  useEffect(() => {
    if (targetInfo?.targetType === 'LINK' && hasYaMetrikaIntegration) {
      initYaMetrika();
    }
  }, [initYaMetrika, targetInfo, hasYaMetrikaIntegration]);

  const activePrizes = prizes.filter(({ inactive }) => !inactive);

  const renderPrizes = () => {
    return activePrizes.map((prize, index) => (
      <div key={`${index}-${prize.value}`} className={styles.prizePreview}>
        <div className={styles.prizePreviewImg}>
          <Icon36GiftOutline />
        </div>
        <div className={styles.prizePreviewName} style={{ color: subtitleColor }}>
          {prize.text}
        </div>
      </div>
    ));
  };

  const renderSecondSection = () => {
    const content = disabled ? (
      attemptsLeftComponent
    ) : (
      <>
        <div className={styles.secondSectionText}>
          <h5 className={styles.title} style={{ color: titleColor }}>
            {titleText}
          </h5>

          <div
            className={styles.subtitle}
            style={{ color: subtitleColor }}
            dangerouslySetInnerHTML={{ __html: formatSubtitle(subtitleText) }}
          />
        </div>

        <div className={styles.secondSectionControls}>
          {collectDataType ? (
            <Controls
              collectDataType={collectDataType}
              email={email}
              phone={phone}
              setEmail={setEmail}
              setPhone={setPhone}
              validationError={validationError}
              collectDataPlaceholder={collectDataPlaceholder}
              gdprChecked={gdprChecked}
              gdprError={gdprError}
              setGdprChecked={setGdprChecked}
              color={subtitleColor}
              errorColor={titleColor}
              privacyPolicyUrl={settings.mainGameSettings.privacyPolicyUrl}
              error={error}
            />
          ) : null}
        </div>

        {startButton && (
          <button
            className={cn(styles.button, {
              [styles.disabledStartButton]: disableStartButton,
            })}
            onClick={handleStartGame}
            style={{ ...startButton.styles }}
            disabled={disableStartButton}
          >
            {resultFetching ? <Loader className={styles.buttonLoader} /> : startButton.text}
          </button>
        )}

        {campaignType !== 'competition' && prizesPreview && Slider && activePrizes.length > 0 && (
          <div className={styles.prizesSliderWrapper}>
            <h5
              className={styles.prizeSliderTitle}
              style={{ color: titleColor, borderTop: `1px solid ${subtitleColor}` }}
            >
              Вы можете выиграть:
            </h5>

            {activePrizes.length > 4 ? (
              <Slider
                dots={false}
                arrows={false}
                slidesToShow={4}
                slidesToScroll={1}
                autoplay={true}
                speed={2000}
                autoplaySpeed={0}
                infinite={true}
                cssEase="linear"
              >
                {renderPrizes()}
              </Slider>
            ) : (
              <div className={styles.prizesWrapper}>{renderPrizes()}</div>
            )}
          </div>
        )}
      </>
    );

    if (campaignType === 'competition') {
      return (
        <>
          <div className={styles.tabsWrapper}>
            <Tabs mode="segmented">
              <TabsItem
                onClick={() => {
                  setSecondSectionContentType('content');
                }}
                selected={secondSectionContentType === 'content'}
              >
                Игра
              </TabsItem>
              <TabsItem
                onClick={() => {
                  setSecondSectionContentType('rating');
                }}
                selected={secondSectionContentType === 'rating'}
              >
                Рейтинг
              </TabsItem>
            </Tabs>
          </div>

          {secondSectionContentType === 'content' && content}
          {secondSectionContentType === 'rating' && <Rating mainGameSettingsId={id} />}
        </>
      );
    }

    return content;
  };

  const disableStartButton = resultFetching || !!result;

  console.log(gameId)

  return (
    <div className={cn(styles.game, { [styles.vk]: Boolean(bridge) })} style={style}>
      {!disabled && (
        <Analytics
          sendAnalytics={sendAnalytics}
          result={result}
          mainGameSettingId={id}
          touched={touched}
        />
      )}

      {bridge ? (
        <VkResultScreen
          title={resultTitle}
          subtitle={resultSubtitle}
          visible={resultScreenVisible}
          settings={settings}
          result={result}
          bridge={bridge}
          spaceId={spaceId as string}
          isDemo={isDemo}
          onGameOver={onGameOver}
          resultError={resultError}
          showJoinButton={showJoinButton}
        />
      ) : (
        <ResultScreen
          title={resultTitle}
          subtitle={resultSubtitle}
          visible={resultScreenVisible}
          mainGameSettings={settings.mainGameSettings}
          result={result}
          resultFetching={resultFetching}
          lockGame={lockGame}
          onCloseResultClick={handleResultsClose}
          resultError={resultError}
          hasPromocode={hasPromocode}
        />
      )}

      {showCloseIcon && <SfCrossMIcon onClick={closeGame} className={styles.closeGameIcon} />}

      <div
        className={cn(styles.mainSection, mainSectionClassname, {
          [styles.mainSectionDisabled]: disabled,
        })}
        style={{
          backgroundImage: view === 'classic' && backgroundImgSrc ? `url(${backgroundImgSrc})` : '',
          backgroundColor: view === 'mono' ? backgroundColor : '',
        }}
      >
        <GameContent
          key={attempts}
          settings={settings}
          started={gameStarted}
          resultFetching={resultFetching}
          startGame={handleStartGame}
          endGame={handleEndGame}
          showResultScreen={showResultScreen}
        />

        {disabled && <div className={styles.gap} />}
      </div>

      <div
        className={cn(styles.secondSection, {
          [styles.smallSecondSection]: smallSecondSection,
          [styles.secondSectionDisabled]: disabled,
          [styles.wheelSecondSection]: gameId === GameId.WHEEL,
        })}
        style={{
          backgroundColor: view === 'mono' ? backgroundColor : secondSectionBgColor || '#fff',
        }}
      >
        {renderSecondSection()}
      </div>

      <span className={styles.madeBy} style={{ color: subtitleColor }}>
        Сделано в{' '}
        <a
          href={bridge ? 'https://vk.com/embedgames' : 'https://embedgames.app'}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
          style={{ color: titleColor }}
          ref={(node) => {
            if (node) {
              node.style.setProperty('color', titleColor, 'important');
            }
          }}
        >
          EmbedGames
        </a>
      </span>
    </div>
  );
};
