import React, { Component } from 'react';
import { config } from 'src/config/config';
import { WheelGame } from 'src/games';
import { WheelSettings } from 'src/games/common/types';
import { GameId } from 'src/store/game/types';
// import { Modal } from 'src/components/Modal';

type Props = {
  getResult: any;
  bridge: any;
};

// @ts-ignore
const WHEEL_DEFAULT_VALUES: WheelSettings = {
  titleText: 'Испытай удачу!',
  subtitleText: 'Вращай колесо и выигрывай призы!',
  buttonText: 'Получить приз',
  titleColor: '#fff',
  subtitleColor: '#969696',
  view: 'mono',
  backgroundColor: '#234E70',
  buttonColor: '#4a61dd',
  buttonTextColor: '#fff',
  backgroundImgSrc: 'https://cdn2.embedgames.app/WHEEL/backgrounds/4/1-min.jpg',
  winTitle: 'Поздравляем! Вы выиграли {prize}',
  winSubtitle: 'Держите промокод: {prize}',
  failTitle: 'Удача сегодня не на вашей стороне...',
  failSubtitle: 'Попробуйте еще раз!',
  slotsColors: [
    '#e91e63',
    '#f06292',
    '#e91e63',
    '#f06292',
    '#e91e63',
    '#f06292',
    '#e91e63',
    '#f06292',
  ],
  slotsTextColor: [
    '#FFF',
    '#FFF',
    '#FFF',
    '#FFF',
    '#FFF',
    '#FFF',
    '#FFF',
    '#FFF',
  ],
  slotsCount: 8,
  pointerColor: '#c2185b',
  // @ts-ignore
  mainGameSettings: {
    gameId: GameId.WHEEL,
  },
  // @ts-ignore
  targetInfo: {
    vkClubID: config.vkClubId,
  },
  prizes: {
    prizes: [
      { text: '-30% на покупку', type: 'PROMO_CODE', chance: 8.33, value: '123wqe' },
      { text: '-50% на покупку', type: 'PROMO_CODE', chance: 8.33, value: '123wqe' },
      { text: 'galaxy note 10', type: 'PROMO_CODE', chance: 8.33, value: '123wqe' },
      { text: 'путешествие', type: 'PROMO_CODE', chance: 8.33, value: '123wqe' },
      { text: 'iphone', type: 'PROMO_CODE', chance: 8.33, value: '123wqe' },
      { text: 'путешествие', type: 'PROMO_CODE', chance: 8.33, value: '123wqe' },
      { text: '-20% на покупку', type: 'PROMO_CODE', chance: 8.33, value: '123wqe' },
      { text: 'путешествие', type: 'PROMO_CODE', chance: 8.33, value: '123wqe' },
      { text: 'mac book pro', type: 'PROMO_CODE', chance: 8.33, value: '123wqe' },
      { text: '-10% на покупку', type: 'PROMO_CODE', chance: 8.33, value: '123wqe' },
      { text: 'тени для глаз', type: 'PROMO_CODE', chance: 8.33, value: '123wqe' },
      { text: '$100', type: 'PROMO_CODE', chance: 8.33, value: '123wqe' },
    ],
  },
  secondSectionBgColor: '#fff',
};

const getResult = (gameId: GameId): Promise<any> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        win: true,
        slotValue: 'TRIP',
        slotText: 'Путешествие',
        slotNumber: 5,
      });
    }, 100);
  });
};

export default class WheelDemo extends Component<Props> {
  render() {
    return (
      <WheelGame
        {...this.props}
        lockGame={() => {}}
        settings={WHEEL_DEFAULT_VALUES}
        getResult={getResult}
        sendAnalytics={(params) =>
          new Promise((resolve) => {
            console.log('send analytics ====>', params);
            resolve({});
          })
        }
      />
    );
  }
}
