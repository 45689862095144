import React, { FC, useCallback, useEffect, useRef } from 'react';

import WheelSvg from './components/WheelSvg';
import WheelSvg4 from './components/WheelSvg4';
import WheelSvg6 from './components/WheelSvg6';
import WheelSvg8 from './components/WheelSvg8';
import WheelSvg10 from './components/WheelSvg10';
import { WheelPointer } from './components/wheel-pointer';
import { GameProps, Prize, WheelSettings } from '../common/types';

import styles from './wheel.module.css';

const WheelSvgComponent: { [key: number]: FC<any> } = {
  4: WheelSvg4,
  6: WheelSvg6,
  8: WheelSvg8,
  10: WheelSvg10,
  12: WheelSvg,
};

const FIVE_TURNS = 1800;

type WheelGameProps = Omit<GameProps, 'settings'> & {
  settings: WheelSettings;
};

const getPrizeDisplayName = (prize: Prize): string => {
  if (!prize.text) {
    return 'Приз без имени';
  }

  if (prize.text.length > 40) {
    return `${prize.text.substring(0, 40)}...`;
  }

  return prize.text;
};

export const Wheel: FC<WheelGameProps> = ({
  settings: { pointerColor, slotsColors, slotsTextColor, prizes, slotsCount },
  started,
  showResultScreen,
  endGame,
}) => {
  const reqId = useRef(0);
  const wheelCurrentAngle = useRef(0);
  const idleAnimation = useRef(true);
  const rotationContainerRef = useRef<HTMLDivElement>(null);
  const oneSlotAngle = 360 / slotsCount;

  const resetWheel = useCallback(() => {
    if (rotationContainerRef.current) {
      rotationContainerRef.current.style.transition = 'none';
      rotationContainerRef.current.style.transform = `rotate(0deg)`;
    }
  }, []);

  const onAnimationEnd = useCallback(() => {
    if (!idleAnimation.current) {
      showResultScreen();

      setTimeout(() => {
        resetWheel();
      }, 1000);
    }
  }, [resetWheel, showResultScreen]);

  useEffect(() => {
    if (started) {
      endGame().then((result) => {
        if (result && rotationContainerRef.current && result.slotNumber !== undefined) {
          idleAnimation.current = false;
          const targetAngle = FIVE_TURNS - oneSlotAngle * result.slotNumber;

          rotationContainerRef.current.style.transform = `rotate(${targetAngle}deg) translateZ(0)`;
          rotationContainerRef.current.style.transition =
            'transform 5s cubic-bezier(0.5, 0.2, 0.05, 0.95)';
        }
      });
    }
  }, [started, endGame, oneSlotAngle]);

  useEffect(() => {
    const rotate = () => {
      if (rotationContainerRef.current && idleAnimation.current) {
        wheelCurrentAngle.current += 0.25;

        if (wheelCurrentAngle.current > 360) {
          wheelCurrentAngle.current = wheelCurrentAngle.current - 360;
        }

        rotationContainerRef.current.style.transform = `rotate(${wheelCurrentAngle.current}deg) translateZ(0)`;

        reqId.current = window.requestAnimationFrame(rotate);
      }
    };

    if (!reqId.current) {
      reqId.current = window.requestAnimationFrame(rotate);
    }

    return () => {
      window.cancelAnimationFrame(reqId.current);
    };
  }, []);

  const WheelComponent = WheelSvgComponent[slotsCount] || WheelSvg;

  return (
    <div className={styles.wheelWrapper}>
      <div className={styles.rotationContainerWpapper}>
        <div
          className={styles.rotationContainer}
          ref={rotationContainerRef}
          onTransitionEnd={onAnimationEnd}
        >
          <WheelComponent className={styles.wheelSvg} slotsColors={slotsColors} />

          {prizes?.prizes?.slice(0, slotsCount).map((prize, index) => {
            if (prize.inactive) {
              return null;
            }

            const rotate = oneSlotAngle * index - 90;

            return (
              <div
                className={styles.couponText}
                key={index}
                style={{ transform: `translateY(-50%) rotate(${rotate}deg)` }}
              >
                <div style={{ color: slotsTextColor[index] }} className={styles.couponTextInner}>
                  {getPrizeDisplayName(prize)}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <WheelPointer className={styles.pointer} color={pointerColor} />
    </div>
  );
};
