import React, { forwardRef, HTMLAttributes } from 'react';

type WheelProps = {
  slotsColors: string[];
} & HTMLAttributes<SVGSVGElement>;

export default forwardRef<SVGSVGElement, WheelProps>(({ slotsColors, ...restProps }, ref) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      viewBox="0 0 1000 1000"
      className="eg-wheel-svg"
      {...restProps}
    >
      <path
        id="slot00"
        d="M420.824 424.865L181.405 197.664C258.779 116.129 367.259 64.11 488.502 60.9352C609.746 57.7605 720.8 104.03 802.335 181.404L575.133 420.826C568.268 414.312 560.59 408.714 552.288 404.173C535.947 395.236 517.102 390.361 497.142 390.884L497.142 390.884C477.18 391.406 458.616 397.259 442.765 407.04C434.712 412.01 427.338 418.001 420.824 424.865Z"
        fill={slotsColors[0]}
      />
      <path
        id="slot01"
        d="M609.113 497.142C608.598 477.405 602.872 459.03 593.288 443.296C588.255 435.037 582.15 427.481 575.133 420.826L802.335 181.404C883.871 258.779 935.889 367.258 939.064 488.503C939.064 488.502 939.063 488.502 939.063 488.502L609.113 497.142ZM609.113 497.142C609.114 497.213 609.115 497.284 609.115 497.354C609.115 497.435 609.116 497.517 609.118 497.598C609.556 517.448 604.662 536.186 595.743 552.435C591.216 560.68 585.647 568.308 579.173 575.131L818.595 802.335C895.969 720.8 942.239 609.747 939.064 488.503"
        fill={slotsColors[1]}
      />
      <path
        id="slot02"
        d="M502.854 609.115L511.497 939.063C390.253 942.239 279.2 895.969 197.664 818.595L424.865 579.175C431.838 585.796 439.652 591.47 448.107 596.05C464.355 604.848 483.059 609.634 502.854 609.115ZM511.497 939.063C632.741 935.889 741.221 883.871 818.595 802.335L579.173 575.131C572.555 582.109 565.047 588.184 556.843 593.201C541.078 602.837 522.651 608.597 502.857 609.115L511.497 939.063Z"
        fill={slotsColors[2]}
      />
      <path
        id="slot03"
        d="M181.405 197.664L420.824 424.865C414.164 431.879 408.463 439.745 403.868 448.256C395.122 464.47 390.365 483.119 390.881 502.856C390.883 502.95 390.888 503.044 390.892 503.137C390.895 503.196 390.897 503.254 390.9 503.313C391.5 523.159 397.368 541.614 407.127 557.375C412.08 565.373 418.041 572.699 424.865 579.175L197.664 818.595C116.129 741.22 64.1103 632.741 60.9351 511.497L60.936 511.496C57.7604 390.253 104.03 279.2 181.405 197.664Z"
        fill={slotsColors[3]}
      />
    </svg>
  );
});
