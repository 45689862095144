import React, { forwardRef, HTMLAttributes } from 'react';

type WheelProps = {
  slotsColors: string[];
} & HTMLAttributes<SVGSVGElement>;

export default forwardRef<SVGSVGElement, WheelProps>(({ slotsColors, ...restProps }, ref) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      viewBox="0 0 1000 1000"
      className="eg-wheel-svg"
      {...restProps}
    >
      <path
        id="slot06"
        fill={slotsColors[6]}
        d="M528.734 394.442L614.065 75.98c-77.282-20.708-155.273-19.428-227.34-.005l85.346 318.514c17.956-4.869 37.397-5.209 56.663-.047z"
      />
      <path
        id="slot05"
        fill={slotsColors[5]}
        d="M577.782 422.814L810.95 189.646c-52.7-52.854-119.603-92.958-196.885-113.666l-85.331 318.462c19.265 5.162 35.932 15.177 49.048 28.372z"
      />
      <path
        id="slot03"
        fill={slotsColors[3]}
        d="M924.462 386.572l-318.513 85.345c4.714 17.788 4.986 37.011-.118 56.061-.04.147-.084.293-.124.439l318.465 85.333c.039-.147.081-.293.121-.44 20.65-77.066 19.436-154.839.169-226.738z"
      />
      <path
        id="slot04"
        fill={slotsColors[4]}
        d="M605.949 471.917l318.513-85.345c-19.926-74.353-59.165-142.42-113.512-196.926L577.782 422.814a109.58 109.58 0 0128.167 49.103z"
      />
      <path
        id="slot07"
        fill={slotsColors[7]}
        d="M472.071 394.489L386.725 75.975c-74.319 20.029-142.335 59.358-196.767 113.787l233.168 233.167a109.573 109.573 0 0148.945-28.44z"
      />
      <path
        id="slot01"
        fill={slotsColors[1]}
        d="M528.517 605.152l85.346 318.511c74.236-19.925 142.199-59.104 196.647-113.351L577.34 577.144a109.588 109.588 0 01-48.823 28.008z"
      />
      <path
        id="slot02"
        fill={slotsColors[2]}
        d="M577.34 577.144l233.17 233.168c52.811-52.618 92.905-119.409 113.663-196.562l-318.465-85.333c-5.212 19.136-15.216 35.694-28.368 48.727z"
      />
      <path
        id="slot00"
        fill={slotsColors[0]}
        d="M472.294 605.077l-85.331 318.461c77.124 20.666 154.955 19.435 226.9.126l-85.346-318.512c-17.832 4.756-37.115 5.045-56.223-.075z"
      />
      <path
        id="slot11"
        fill={slotsColors[11]}
        d="M472.294 605.077c-19.108-5.12-35.663-15.011-48.729-28.046L190.399 810.196c52.651 52.695 119.439 92.676 196.564 113.341l85.331-318.46z"
      />
      <path
        id="slot08"
        fill={slotsColors[8]}
        d="M395.197 471.539c5.105-19.05 14.951-35.563 27.929-48.61L189.958 189.762c-52.636 52.632-92.573 119.379-113.223 196.447l-.115.441 318.465 85.332c.038-.148.073-.295.112-.443z"
      />
      <path
        id="slot09"
        fill={slotsColors[9]}
        d="M395.085 471.981L76.62 386.649c-20.6 77.195-19.274 155.085.154 227.06l318.515-85.346c-4.875-17.863-5.259-37.204-.204-56.382z"
      />
      <path
        id="slot10"
        fill={slotsColors[10]}
        d="M395.288 528.363L76.774 613.709c20.029 74.202 59.297 142.113 113.625 196.487L423.566 577.03a109.577 109.577 0 01-28.278-48.667z"
      />
    </svg>
  );
});
