import {
  ActionSheet,
  ActionSheetItem,
  Button,
  IOS,
  Select,
  SimpleCell,
  usePlatform,
  Input,
  FormLayoutGroup,
  FormLayout,
  Switch,
  Div,
  Textarea,
  Radio,
} from '@vkontakte/vkui';
import React, { ChangeEvent, FC, useCallback } from 'react';
import { WrappedFieldArrayProps, formValueSelector, change } from 'redux-form';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import {
  Icon24ErrorCircleOutline,
  Icon16Add,
  Icon28GiftOutline,
  Icon24MoreVertical,
} from '@vkontakte/icons';

import { RootState } from 'src/core/rootReducer';
import { Prize } from 'src/games/common/types';
import { vkActions } from 'src/store/vk/reducer';
import { ModalName } from 'src/store/vk/types';
import { TargetsInitState } from 'src/store/targets/reducers';
import { getVkQuery } from 'src/vk-app/utils/query';
import { VkTarif } from 'src/store/auth/types';
import { FormStatus } from 'src/components';
import { pluralize } from 'src/core/utils/pluralize';
import { GameId } from 'src/store/game/types';
import { ImageSelect } from '..';
import { config } from 'src/config/config';
import { generateTimeZones } from 'src/core/utils/tz';
import { getScore } from './get-score';

const integerRegexp = /^[0-9]{1,}$/;

const timeZones = generateTimeZones();

type PromocodesProps = WrappedFieldArrayProps & {
  chancesEqual?: boolean;
  editableAmount?: boolean;
  disableChances?: boolean;
  count?: number;
  uploadImages?: boolean;
};

const getValueFieldDescription = (value: Prize, gameId: GameId): string => {
  if (gameId === GameId.WHEEL) {
    let description =
      'Чтобы приз отправился игроку в ЛС, заполните это поле. Если не заполнять - слот будет проигрышным.';

    if (value.type === 'LINK') {
      description += ' Указывайте полный путь, например https://yandex.ru';
    }

    return description;
  }

  return value.type === 'PROMO_CODE'
    ? 'Отправится в ЛС игроку.'
    : 'Отправится в ЛС игроку. Указывайте полный путь, например https://yandex.ru';
};

export const Promocodes: FC<PromocodesProps> = ({
  fields,
  editableAmount = true,
  chancesEqual,
  disableChances = false,
  count = fields.length,
  uploadImages = false,
}) => {
  const formSelector = formValueSelector('game');
  const dispatch = useDispatch();

  const gameId = useSelector<RootState, GameId>((state) => formSelector(state, 'gameId'));
  const mainGameSettingsId = useSelector<RootState, number>((state) =>
    formSelector(state, 'mainGameSettingsId')
  );

  const targets = useSelector<RootState, TargetsInitState>((state) => state.targets);

  const query = getVkQuery();

  const currentTarget = targets.data.targets.find(
    (t) => t.targetType === 'VK_CLUB' && t.vkClubID === `${query.vk_group_id}`
  );

  const ModalContent = () => {
    const name = (window as any).editedName;

    const value = useSelector<RootState, Prize>((state) => formSelector(state, name));
    const timeZone = useSelector<RootState, string | undefined>((state) =>
      formSelector(state, 'mainGameSettings.timeZone')
    );
    const prizeType = value.type;
    const isLooseSlot = prizeType === 'LOOSE_SLOT';

    const valueTopLabel = `${prizeType === 'PROMO_CODE' ? 'Промокод' : 'Ссылка'} (${
      value.value ? value.value.length : 0
    }/500)`;

    const prizeDescriptionLabel = `Описание приза (${value.description?.length || 0}/500)`;

    let valueBottomLabel = getValueFieldDescription(value, gameId);

    const limitsType = value.limitsType || 'common';

    const isIOS = window.origin === 'null';
    const possibleLimits = currentTarget?.vkTarif !== VkTarif.FREE;

    const prizeLimit = value?.limit ? +value.limit : 0;
    const prizeWinsCount = value?.winsCount ? +value.winsCount : 0;

    const shouldRenderPremiumFeatures = isIOS ? currentTarget?.vkTarif !== VkTarif.FREE : true;
    const dailyLimit = limitsType === 'daily';

    const handleLimitTypeChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(change('game', `${name}.limitsType`, event.target.value));
      },
      [name]
    );

    return (
      <ModalContentWrap>
        {value.hasLimit && possibleLimits && prizeWinsCount ? (
          <Div>
            <FormStatus
              className="prize-wins-count"
              content={
                <>
                  {pluralize(prizeWinsCount, 'Разыгран', 'Разыграно', 'Разыграны')} {prizeWinsCount}{' '}
                  {pluralize(prizeWinsCount, 'приз', 'приза', 'призов')}.
                  {prizeWinsCount >= prizeLimit
                    ? ' Лимит достигнут. Этот промокод больше не участвует в розыгрыше'
                    : null}
                </>
              }
            />
          </Div>
        ) : null}

        <FormLayout>
          <FormLayoutGroup top="Тип приза">
            <Select
              value={prizeType}
              onChange={(event) => {
                dispatch(change('game', `${name}.type`, event.target.value));
              }}
            >
              <option value="PROMO_CODE">Промокод</option>
              <option value="LINK">Ссылка</option>
              {[GameId.LUCKY, GameId.WHEEL].includes(gameId) && (
                <option value="LOOSE_SLOT">Проигрышный слот</option>
              )}
            </Select>
          </FormLayoutGroup>

          <FormLayoutGroup
            top={`Название приза (${value.text ? value.text.length : 0}/40)`}
            bottom={!isLooseSlot && 'Будет отображаться в загловке выигрыша'}
          >
            <Input
              value={value.text}
              onChange={(event) => {
                dispatch(change('game', `${name}.text`, event.target.value));
              }}
              maxLength={40}
            />
          </FormLayoutGroup>

          {!isLooseSlot && (
            <FormLayoutGroup top={valueTopLabel} bottom={valueBottomLabel}>
              <Input
                value={value.value}
                onChange={(event) => {
                  dispatch(change('game', `${name}.value`, event.target.value));
                }}
                maxLength={500}
              />
            </FormLayoutGroup>
          )}

          {!isLooseSlot && (
            <FormLayoutGroup top={prizeDescriptionLabel} bottom="Отправится в ЛС игроку.">
              <Textarea
                value={value.description}
                onChange={(event) => {
                  dispatch(change('game', `${name}.description`, event.target.value));
                }}
                maxLength={500}
              />
            </FormLayoutGroup>
          )}

          {!disableChances && (
            <FormLayoutGroup top="Вероятность выигрыша (%)">
              <Input
                value={value.chance || ''}
                onChange={(event) => {
                  const re = new RegExp(/^\d{0,2}\.?\d{0,4}$/);
                  const value = event.target.value.replace(',', '.');

                  const number = +value;

                  if (!isNaN(number) && number > 100) {
                    return;
                  }

                  if (re.test(value)) {
                    dispatch(change('game', `${name}.chance`, value));
                  }
                }}
                type="tel"
                disabled={chancesEqual}
              />
            </FormLayoutGroup>
          )}

          {uploadImages && (
            <FormLayoutGroup top="Загрузите изображение">
              <Div>
                <ImageSelect
                  // @ts-ignore
                  input={{ value: value.imageSrc }}
                  title="Загрузите изображение"
                  uploadButtonText="Загрузить"
                  canUpload={true}
                  uploadUrl={`${config.api}/vk/upload-lucky-image/${mainGameSettingsId}`}
                  setPreviewSrc={(src: string) => {
                    dispatch(change('game', `${name}.imageSrc`, src));
                  }}
                />

                {value.imageSrc && (
                  <Button
                    onClick={() => {
                      dispatch(change('game', `${name}.imageSrc`, ''));
                    }}
                    className="delete-prize-image-button"
                    size="l"
                  >
                    Удалить
                  </Button>
                )}
              </Div>
            </FormLayoutGroup>
          )}

          {shouldRenderPremiumFeatures && !isLooseSlot && (
            <FormLayoutGroup top="Ограничить количество призов">
              <PrizeLimitsWrap>
                <Switch
                  checked={possibleLimits ? value.hasLimit : false}
                  onChange={(event) => {
                    dispatch(change('game', `${name}.hasLimit`, event.target.checked));
                  }}
                  className="FormField"
                  disabled={!possibleLimits}
                />

                {possibleLimits && (
                  <>
                    <PrizeLimitsInputWrap hide={!value.hasLimit}>
                      <Input
                        value={value.limit || ''}
                        onChange={(event) => {
                          if (event.target.value === '' || integerRegexp.test(event.target.value)) {
                            const value = +event.target.value;

                            if (value < 10000) {
                              dispatch(change('game', `${name}.limit`, event.target.value));
                            }
                          }
                        }}
                        className="prize-limits-input"
                      />

                      <span className="FormLayout__row-bottom">Лимит</span>
                    </PrizeLimitsInputWrap>

                    <PrizeLimitsTypeWrapper hide={!value.hasLimit}>
                      <Radio
                        name="limitsType"
                        value="common"
                        checked={limitsType === 'common'}
                        onChange={handleLimitTypeChange}
                      >
                        Всего
                      </Radio>
                      <Radio
                        name="limitsType"
                        value="daily"
                        checked={limitsType === 'daily'}
                        onChange={handleLimitTypeChange}
                      >
                        В день
                      </Radio>
                    </PrizeLimitsTypeWrapper>
                  </>
                )}
              </PrizeLimitsWrap>

              {!possibleLimits && (
                <div className="FormLayout__row-bottom">
                  Доступно только для премиум-сообществ. При достижении лимита, этот промокод больше
                  не будет разыгрываться
                </div>
              )}
            </FormLayoutGroup>
          )}

          {shouldRenderPremiumFeatures && possibleLimits && !isLooseSlot && (
            <TimezoneWrapper hide={!value.hasLimit}>
              {dailyLimit && (
                <FormLayoutGroup top="Часовой пояс">
                  <Select
                    value={timeZone || '+03:00'}
                    onChange={(event) => {
                      dispatch(change('game', 'mainGameSettings.timeZone', event.target.value));
                    }}
                  >
                    {timeZones.map((tz) => (
                      <option value={tz.value} key={tz.value}>
                        {tz.label}
                      </option>
                    ))}
                  </Select>
                </FormLayoutGroup>
              )}

              <Div>
                <FormStatus
                  content={
                    <>
                      {dailyLimit
                        ? `Каждый день будет разыгрываться не более ${prizeLimit} ${pluralize(
                            prizeLimit,
                            'приза',
                            'призов',
                            'призов'
                          )}`
                        : `Будет разыграно не более ${prizeLimit} ${pluralize(
                            prizeLimit,
                            'приза',
                            'призов',
                            'призов'
                          )}`}
                    </>
                  }
                />
              </Div>
            </TimezoneWrapper>
          )}
        </FormLayout>
      </ModalContentWrap>
    );
  };

  const prizes = useSelector<RootState, Prize[]>((state) => formSelector(state, fields.name));

  const platform = usePlatform();

  const closeActions = useCallback(() => {
    dispatch(vkActions.setPopoutContent({ popout: null }));
  }, [dispatch]);

  const handleAddNewItemClick = () => {
    fields.push({
      value: '',
      text: '',
      type: 'PROMO_CODE',
      chance: 0,
    });

    (window as any).editedName = `${fields.name}[${fields.length}]`;
    dispatch(vkActions.setActiveModal({ modal: ModalName.MAIN }));
    dispatch(vkActions.setModalContent({ title: 'Создать', content: ModalContent }));
  };

  const handleEditItemClick = (prize: Prize, name: string) => {
    (window as any).editedName = name;
    dispatch(vkActions.setModalContent({ title: 'Редактировать', content: ModalContent }));
    dispatch(vkActions.setActiveModal({ modal: ModalName.MAIN }));
  };

  const handlePrizeActionsClick = (prize: Prize, index: number, name: string) => {
    dispatch(
      vkActions.setPopoutContent({
        popout: (
          <ActionSheet onClose={closeActions}>
            <ActionSheetItem
              autoclose
              onClick={() => {
                handleEditItemClick(prize, name);
              }}
            >
              Редактировать
            </ActionSheetItem>

            {editableAmount && (
              <ActionSheetItem
                autoclose
                mode="destructive"
                onClick={() => {
                  fields.remove(index);
                }}
              >
                Удалить
              </ActionSheetItem>
            )}

            {platform === IOS && (
              <ActionSheetItem autoclose mode="cancel">
                Отменить
              </ActionSheetItem>
            )}
          </ActionSheet>
        ),
      })
    );
  };

  return (
    <PromocodesList>
      {fields.map((name, index) => {
        if (index >= count) {
          return null;
        }

        const prize = prizes[index] as Prize;

        let showAttention = false;

        if (prize.hasLimit) {
          const prizeLimit = prize?.limit ? +prize.limit : 0;
          const prizeWinsCount = prize?.winsCount ? +prize.winsCount : 0;

          showAttention = prizeWinsCount >= prizeLimit;
        }

        const getPrizeScore = () => {
          const [min, max] = getScore(fields.length, index);

          return `${min} - ${max} очков`;
        };

        return (
          <SimpleCell
            key={name}
            before={
              <GiftPreview>
                {prize.imageSrc ? <PrizePreview src={prize.imageSrc} /> : <Icon28GiftOutline />}
              </GiftPreview>
            }
            after={
              <>
                {showAttention && <Icon24ErrorCircleOutline />}
                {!disableChances && <span className="chance-preview">{prize.chance} %</span>}
                <Icon24MoreVertical
                  onClick={() => {
                    handlePrizeActionsClick(prize, index, name);
                  }}
                />
              </>
            }
            className="eg-simple-cell"
            disabled={true}
          >
            <span>{prize.text}</span>
            {disableChances && <Score className="RichCell__caption">{getPrizeScore()}</Score>}
          </SimpleCell>
        );
      })}

      {editableAmount && (
        <Button
          className="add-prize-button"
          mode="outline"
          before={<Icon16Add />}
          onClick={handleAddNewItemClick}
        >
          Добавить приз
        </Button>
      )}
    </PromocodesList>
  );
};

const PromocodesList = styled.div`
  .add-prize-button {
    margin-top: 10px;
  }

  .eg-simple-cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .chance-preview {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    font-size: 12px;
    border-radius: 20px;
    padding: 2px 8px;
    color: var(--button_primary_foreground);
    background-color: var(--button_primary_background);
  }
`;

const GiftPreview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 4px 15px 4px 0;
  border-radius: 50%;
  background-color: var(--background_content);
  box-shadow: 0 0 5px var(--accent);
  overflow: hidden;
`;

const PrizePreview = styled.div<{ src: string }>`
  width: 50px;
  height: 50px;
  background-image: url(${(p) => p.src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

const ModalContentWrap = styled.div`
  padding-bottom: 20px;

  .prize-limits-input {
    margin: 0;
    width: 100px;
  }

  .prize-wins-count {
    padding: 6px 16px;
    min-height: initial;
    border: 1px solid #13a454;
    background-color: #ebf9f1;

    > div {
      font-size: 12px;
    }
  }

  .delete-prize-image-button {
    margin: 0 !important;
  }
`;

const PrizeLimitsInputWrap = styled.div<{ hide: boolean }>`
  ${(p) =>
    p.hide &&
    `
    opacity: 0;
    position: relative;
    z-index: -1;
    pointer-events: none;
  `};

  margin: 0;
`;

const PrizeLimitsTypeWrapper = styled.div<{ hide: boolean }>`
  ${(p) =>
    p.hide &&
    `
    opacity: 0;
    position: relative;
    z-index: -1;
    pointer-events: none;
  `};

  display: flex;
  flex-direction: column;
`;

const PrizeLimitsWrap = styled.div`
  display: flex;
  align-items: flex-start;
`;

const TimezoneWrapper = styled.div<{ hide: boolean }>`
  ${(p) =>
    p.hide &&
    `
    opacity: 0;
    position: relative;
    z-index: -1;
    pointer-events: none;
  `};

  display: flex;
  flex-direction: column;
  margin-top: -12px;
`;

const Score = styled.div`
  font-size: 11px;
  margin-bottom: 4px;
`;
